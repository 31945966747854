import React from "react";
import "assets/css/SponsorImage.css";

function Sponsor() {
  return (
    <div>
      <div className="container text-center mt-5">
        <p className="header-font" style={{ fontSize: "40px" }}>
          SPONSOR
        </p>
      </div>
      <div className="border-top mx-auto" style={{ width: "80%" }}></div>
      {/* <div className="container p-5">
        <div className="row text-center">
          <div className="col-12">
            <img
              src={require("assets/img/2024_sumsum_sponsor/sumsum_sponsor_a.png")}
              alt="infor_image"
              className="sponsor-image"
            />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-12">
            <img
              src={require("assets/img/2024_sumsum_sponsor/sumsum_sponsor_b.png")}
              alt="infor_image"
              className="sponsor-image"
            />
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="row picture-ready-conter-margin">
          <div className="col text-center">
            <i className="bi bi-chat-left-dots picture-ready-icon-size"></i>
            <p className="picture-ready-font-size ">준비중입니다.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sponsor;
