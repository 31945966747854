import React from "react";

import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import Bold from "components/Bold";
import Download from "components/Download";
import { Helmet } from "react-helmet";
import "assets/css/Outline.css";

function Info() {
  return (
    <div>
      <Helmet>
        <title>ycf - 대회안내</title>
      </Helmet>
      <Header />
      <Bread first="대회정보" second={"대회안내"} />
      <ExtraBold name="대회안내" />

      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/hill_climb_info/outline_1.png")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/hill_climb_info/outline_2.png")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/hill_climb_info/outline_3.png")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/hill_climb_info/outline_4.png")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/hill_climb_info/outline_5.png")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Info;
