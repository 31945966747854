import React from "react";
import "assets/css/Footer.css";

function Footer() {
  return (
    <div className="footer-backgroud-color my-footer">
      <div className="footer-container footer-font">
        <div className="footer-logo">
          <img
            src={require("../assets/img/ycf_sub_logo.png")}
            alt="ycf_lgo"
            style={{
              width: "10%",
              height: "5%",
              minWidth: "110px",
              minHeight: "55px",
            }}
          />
        </div>
        <div className="footer-info">
          <span>단체명 : 여수시사이클연맹 </span>
          <span className="footer-vertical-bar">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span>주소 : 전남 여수시 진남체육관길 74(오림동)</span>
          <span className="footer-vertical-bar">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span>이메일 : yeosubicycle@naver.com</span>
        </div>
      </div>
      <p className="footer-copyright">
        copyright 여수시사이클연맹 ALL RIGHT RESERVED. Icons by Icons8
      </p>
    </div>
  );
}

export default Footer;
