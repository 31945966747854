import React, { useState, useEffect, useRef } from "react";

import DaumPost from "components/DaumPost";
import { useAxios } from "hooks/useAxios";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "assets/css/InputForm.css";
import "assets/css/Global.css";

function IndividualInputForm() {
  const [isValid, setIsValid] = useState({
    name: false,
    phoneNumber: false,
    birthDate: false,
    gender: false,
    zipCode: false,
    address: false,
    zipAndAddress: false,
    detailAddress: false,
    course: false,
    cycle: false,
    agree: false,
  });

  const [isDisable, setIsDisable] = useState(true);
  const [inputData, setInputData] = useState({
    name: "",
    phoneNumber: "",
    birthDate: "",
    gender: "",
    zipCode: "",
    address: "",
    detailAddress: "",
    course: "hill",
    cycle: "",
    agree: false,
  });
  const [warningText, setWarningText] = useState(false);

  const navigate = useNavigate();

  const nameRef = useRef();
  const phoneNumberRef = useRef();
  const birthDateRef = useRef();
  const genderRef = useRef();
  const detailAddressRef = useRef();
  const agreeRef = useRef();
  const courseRef = useRef();
  const cycleRef = useRef();
  const { accessAxios } = useAxios();

  const allInputIsValue = () => {
    if (
      inputData.name !== "" &&
      inputData.phoneNumber !== "" &&
      inputData.birthDate !== "" &&
      inputData.gender !== "" &&
      inputData.zipCode !== "" &&
      inputData.address !== "" &&
      inputData.detailAddress !== "" &&
      inputData.course !== "" &&
      inputData.cycle !== "" &&
      inputData.agree !== false
    ) {
      setIsDisable(false);
    }
  };

  const getAddress = (data) => {
    setInputData({
      ...inputData,
      zipCode: data.zipCode,
      address: data.address,
      detailAddress: data.detailAddress,
    });

    if (isValid.zipAndAddress === true) {
      setIsValid({ ...isValid, zipAndAddress: false });
    } else if (isValid.detailAddress === true) {
      setIsValid({ ...isValid, detailAddress: false });
    }
  };

  const handleGetData = (e) => {
    if (e.target.id === "name") {
      setInputData({ ...inputData, name: e.target.value });

      if (isValid.name === true) {
        setIsValid({ ...isValid, name: false });
      }
    } else if (e.target.id === "phone") {
      setInputData({ ...inputData, phoneNumber: e.target.value });

      if (isValid.phoneNumber === true) {
        setIsValid({ ...isValid, phoneNumber: false });
      }
    } else if (e.target.id === "yymmdd") {
      setInputData({ ...inputData, birthDate: e.target.value });

      if (isValid.birthDate === true) {
        setIsValid({ ...isValid, birthDate: false });
      }
    } else if (e.target.id === "male") {
      setInputData({ ...inputData, gender: e.target.value });

      if (isValid.gender === true) {
        setIsValid({ ...isValid, gender: false });
      }
    } else if (e.target.id === "female") {
      setInputData({ ...inputData, gender: e.target.value });

      if (isValid.gender === true) {
        setIsValid({ ...isValid, gender: false });
      }
    }
    // else if (e.target.id === "granfondo") {
    //   setInputData({ ...inputData, course: e.target.value });

    //   if (isValid.course === true) {
    //     setIsValid({ ...isValid, course: false });
    //   }
    // } else if (e.target.id === "mediofondo") {
    //   setInputData({ ...inputData, course: e.target.value });

    //   if (isValid.course === true) {
    //     setIsValid({ ...isValid, course: false });
    //   }
    // }
    else if (e.target.id === "road") {
      setInputData({ ...inputData, cycle: e.target.value });

      if (isValid.cycle === true) {
        setIsValid({ ...isValid, cycle: false });
      }
    } else if (e.target.id === "mtb") {
      setInputData({ ...inputData, cycle: e.target.value });

      if (isValid.cycle === true) {
        setIsValid({ ...isValid, cycle: false });
      }
    } else if (e.target.id === "etc") {
      setInputData({ ...inputData, cycle: e.target.value });

      if (isValid.cycle === true) {
        setIsValid({ ...isValid, cycle: false });
      }
    }

    allInputIsValue();
  };

  const onClickSubmit = async () => {
    const phoneRegex =
      /^(01[0-9]{8}|010[0-9]{8}|011[0-9]{7}|016[0-9]{7}|017[0-9]{7}|018[0-9]{7}|019[0-9]{7})$/;
    const birthDateBrRegex =
      /\d{2}([0][13578]|[1][012])([0][1-9]|[12][0-9]|[3][01])$/;

    if (inputData.name === "") {
      nameRef.current.focus();
      setIsValid({ ...isValid, name: true });
    } else if (
      inputData.phoneNumber === "" ||
      !phoneRegex.test(inputData.phoneNumber)
    ) {
      phoneNumberRef.current.focus();
      setIsValid({ ...isValid, phoneNumber: true });
    } else if (inputData.birthDate === "") {
      birthDateRef.current.focus();
      setIsValid({ ...isValid, birthDate: true });
    } else if (inputData.gender === "") {
      genderRef.current.focus();
      setIsValid({ ...isValid, gender: true });
    } else if (inputData.zipCode === "") {
      genderRef.current.focus();
      setIsValid({ ...isValid, zipAndAddress: true });
    } else if (inputData.detailAddress === "") {
      genderRef.current.focus();
      setIsValid({ ...isValid, detailAddress: true });
    }
    // else if (inputData.course === "") {
    //   courseRef.current.focus();
    //   setIsValid({ ...isValid, course: true });
    // }
    else if (inputData.cycle === "") {
      cycleRef.current.focus();
      setIsValid({ ...isValid, cycle: true });
    } else if (inputData.agree === false) {
      agreeRef.current.focus();
      setIsValid({ ...isValid, agree: true });
      setWarningText(true);
    } else {
      try {
        const result = await accessAxios.post("/api/individual", {
          ...inputData,
        });

        if (result.data.resultCode === "00") {
          navigate("/individual-complet", { replace: true });
        } else {
          Swal.fire({
            title: "개인참가신청을 실패했습니다.",
            text: "관리자에게 문의해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const autoInput = () => {
    if (sessionStorage.getItem("loginData")) {
      if (
        JSON.parse(sessionStorage.getItem("loginData")).loginType === "naver"
      ) {
        const socialData = JSON.parse(sessionStorage.getItem("loginData")).info;

        setInputData({
          ...inputData,
          name: socialData.name,
          phoneNumber: socialData.phoneNumber,
          birthDate: socialData.birthDate,
          gender: socialData.gender,
        });
        nameRef.current.value = socialData.name;
        phoneNumberRef.current.value = socialData.phoneNumber;
        birthDateRef.current.value = socialData.birthDate;
      } else if (
        JSON.parse(sessionStorage.getItem("loginData")).loginType === "kakao"
      ) {
        const socialData = JSON.parse(sessionStorage.getItem("loginData")).info;

        setInputData({
          ...inputData,
          name: socialData.name,
          phoneNumber: socialData.phoneNumber,
          birthDate: socialData.birthDate,
          gender: socialData.gender,
          email: socialData.email,
        });
        nameRef.current.value = socialData.name;
        phoneNumberRef.current.value = socialData.phoneNumber;
        birthDateRef.current.value = socialData.birthDate;
      }
    }
  };
  const handleCheckBox = (e) => {
    if (e.target.checked === true) {
      setInputData({ ...inputData, agree: true });
      setIsValid({ ...isValid, agree: true });
      setWarningText(false);
    } else {
      setInputData({ ...inputData, agree: false });
      setIsValid({ ...isValid, agree: false });
    }
  };

  useEffect(() => {}, [inputData, isValid, isDisable]);

  useEffect(() => {
    autoInput();
  }, []);

  return (
    <div className="container container-margin-left">
      <form>
        <div className="row mb-3">
          <label htmlFor="name" className="col-sm-2 col-form-label bold-font">
            이름<span className="essential">*</span>
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className={classnames("form-control", "input-max-width", {
                "is-invalid": isValid["name"],
              })}
              id="name"
              onChange={handleGetData}
              ref={nameRef}
              maxLength={20}
            />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="phone" className="col-sm-2 col-form-label bold-font">
            전화번호<span className="essential">*</span>
          </label>
          <div className="col-sm-10">
            <input
              type="tel"
              placeholder="(-)제외하고 입력 예시) 01012341234"
              className={classnames("form-control input-max-width", {
                "is-invalid": isValid.phoneNumber,
              })}
              id="phone"
              onChange={handleGetData}
              ref={phoneNumberRef}
              maxLength={11}
            />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="yymmdd" className="col-sm-2 col-form-label bold-font">
            생년월일<span className="essential">*</span>
          </label>
          <div className="col-sm-10">
            <input
              type="tel"
              placeholder="예)990101"
              className={classnames("form-control input-max-width", {
                "is-invalid": isValid.birthDate,
              })}
              id="yymmdd"
              onChange={handleGetData}
              ref={birthDateRef}
              maxLength={6}
            />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="gender" className="col-sm-2 col-form-label bold-font">
            성별<span className="essential">*</span>
          </label>
          <div className="col-sm-10">
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="male"
              autoComplete="off"
              value="male"
              onChange={handleGetData}
              ref={genderRef}
              checked={inputData.gender === "male" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.gender,
                "btn-outline-danger": isValid.gender,
              })}
              htmlFor="male"
            >
              남자
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="female"
              autoComplete="off"
              value="female"
              onChange={handleGetData}
              checked={inputData.gender === "female" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.gender,
                "btn-outline-danger": isValid.gender,
              })}
              htmlFor="female"
            >
              여자
            </label>
          </div>
        </div>
        <DaumPost
          getAddress={getAddress}
          ref={detailAddressRef}
          isValid={isValid}
        />
        <div className="mt-5"></div>
        <div className="row mb-3">
          <label htmlFor="gender" className="col-sm-2 col-form-label bold-font">
            대회코스
            {/* <span className="essential">*</span> */}
          </label>
          <div className="col-sm-10">
            {/* <input
              type="radio"
              className="btn-check"
              name="course"
              id="granfondo"
              autoComplete="off"
              value="granfondo"
              onChange={handleGetData}
              ref={courseRef}
              checked={inputData.course === "granfondo" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.course,
                "btn-outline-danger": isValid.course,
              })}
              htmlFor="granfondo"
            >
              그란폰도
            </label>
            <input
              type="radio"
              className="btn-check"
              name="course"
              id="mediofondo"
              autoComplete="off"
              value="mediofondo"
              onChange={handleGetData}
              checked={inputData.course === "mediofondo" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.course,
                "btn-outline-danger": isValid.course,
              })}
              htmlFor="mediofondo"
            >
              메디오폰도
            </label> */}
            <input
              type="radio"
              className="btn-check"
              name="course"
              id="hill"
              autoComplete="off"
              ref={courseRef}
              value="hill"
              onChange={handleGetData}
              checked={true}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.course,
                "btn-outline-danger": isValid.course,
              })}
              htmlFor="hill"
            >
              힐클라임
            </label>
          </div>
        </div>
        <div className="row mb-3 ps-2">
          <p style={{ padding: 0, margin: 0 }}>MG새마을금고</p>
          <p style={{ padding: 0, margin: 0 }}>
            예금주 : 여수시자전거연맹&#40;시장배&#41;
          </p>
          <p style={{ padding: 0, margin: 0 }}>
            참가비 입금계좌번호 : 9002-2006-8638-3
          </p>
        </div>
        <div className="row mb-3">
          <label htmlFor="gender" className="col-sm-2 col-form-label bold-font">
            구분<span className="essential">*</span>
          </label>
          <div className="col-sm-10">
            <input
              type="radio"
              className="btn-check"
              name="cycle"
              id="road"
              autoComplete="off"
              value="ROAD"
              onChange={handleGetData}
              ref={cycleRef}
              checked={inputData.cycle === "ROAD" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.cycle,
                "btn-outline-danger": isValid.cycle,
              })}
              htmlFor="road"
            >
              ROAD
            </label>
            <input
              type="radio"
              className="btn-check"
              name="cycle"
              id="mtb"
              autoComplete="off"
              value="MTB"
              onChange={handleGetData}
              checked={inputData.cycle === "MTB" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.cycle,
                "btn-outline-danger": isValid.cycle,
              })}
              htmlFor="mtb"
            >
              MTB
            </label>
            <input
              type="radio"
              className="btn-check"
              name="cycle"
              id="etc"
              autoComplete="off"
              value="기타"
              onChange={handleGetData}
              checked={inputData.cycle === "기타" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.cycle,
                "btn-outline-danger": isValid.cycle,
              })}
              htmlFor="etc"
            >
              기타
            </label>
          </div>
        </div>
        <div className="mt-5"></div>
        <div className="row mt-3">
          <label
            htmlFor="argee"
            className="col-sm-2 col-form-label bold-font"
          ></label>
          <div className="col-sm-10">
            <input
              type="checkbox"
              className="btn-check"
              id="agree"
              autoComplete="off"
              onChange={handleCheckBox}
              ref={agreeRef}
            />
            <label
              className={classnames("btn", "bold-font", "btn-outline-primary")}
              htmlFor="agree"
            >
              약관동의
            </label>
            <p
              className={classnames({
                "warning-text": warningText,
                "none-display": !warningText,
              })}
            >
              약관을 동의해주세요.
            </p>
          </div>
        </div>
        <div className="row mt-1">
          <label
            htmlFor="agree-text"
            className="col-sm-2 col-form-label bold-font"
          ></label>
          <div className="col-sm-10">
            <div>
              <textarea
                className="form-control input-max-width footer-font"
                id="exampleFormControlTextarea1"
                rows="3"
                readOnly
                value={`개인정보 활용 동의
1. 개인정보의 수집·이용 목적
 - 귀하의 개인정보는 자전거 대회 접수와 관련하여 개인의 참가정보를 조회하기 위한 목적으로 수집·이용됩니다.
2. 수집·이용할 개인정보의 항목
 - 개인 또는 법인식별 정보(성명, 성별, 생년월일, 주소, 연락처(휴대전화번호), e-mail주소)
3. 개인정보의 보유·이용 기간
 - 귀하의 개인 또는 법인정보는 수집·이용에 관한 동의일로부터 본인에 대한 신용정보 제공·조회 동의의 효력기간까지 보유·이용됩니다.
 - 단, 신용정보 제공·조회 동의의 효력 기간 종료 후에는 금융사고 조사, 분쟁 해결, 민원처리 및 법령상 의무이행만을 위하여 보유·이용됩니다.
4. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
 - 위 개인 또는 법인정보의 수집·이용에 동의는 본대회의 수행을 위해 필수적이므로 이에 동의하셔야 이후 절차를 진행할 수 있습니다. 선택항목의 수집·이용에 거부할 수 있으며, 다만 동의 하지 않으시는 경우 본 대회의 참여가 불가합니다.`}
                style={{ fontSize: "10px" }}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <label
            htmlFor="name"
            className="col-sm-2 col-form-label bold-font"
          ></label>
          <div className="col mt-2 d-flex align-items-center">
            <button
              type="button"
              className={classnames(
                "btn",
                "btn-outline-primary",
                "input-btn-width",
                "bold-font"
              )}
              onClick={onClickSubmit}
            >
              개인참가신청
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default IndividualInputForm;
