import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";
import { useVerify } from "./useVerify";
import Bold from "components/Bold";
import Download from "components/Download";

export const useFrequent = () => {
  const [frequentData, setFrequentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { accessAxios } = useAxios();
  const { isVerify } = useVerify();

  const writeFrequent = () => {
    navigate("/write-frequent");
  };

  const fetchingData = async () => {
    try {
      setLoading(true);

      const result = await accessAxios.post("/api/read-frequent");

      setLoading(false);
      setFrequentData(result.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const accordionMap = (frequentData) => {
    const createAccordion = frequentData.map((data) => (
      <div className="accordion-item" key={data.FREQUENT_ID}>
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${data.FREQUENT_ID}`}
            aria-expanded="false"
            aria-controls={`collapse${data.FREQUENT_ID}`}
          >
            {data.FREQUENT_TITLE}
          </button>
        </h2>
        {isVerify ? (
          <>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => {
                modifyFrequent(data);
              }}
            >
              수정
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                deleteFrequent(data);
              }}
            >
              삭제
            </button>
          </>
        ) : (
          <></>
        )}

        <div
          id={`collapse${data.FREQUENT_ID}`}
          className="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div
            className="accordion-body"
            dangerouslySetInnerHTML={{
              __html: data.FREQUENT_CONTENT,
            }}
          ></div>

          {data.FREQUENT_TITLE === "단체접수 엑셀파일은 어디서 다운받나요?" ? (
            <div className="container mb-3">
              <div className="row">
                <div className="col">
                  <Download
                    url={`/api/format-excel-file`}
                    downloadFileName={"2025년_여수_힐클라임_단체접수_서식.xlsx"}
                    buttonName={"힐클라임 단체접수 엑셀파일 서식 다운로드"}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    ));

    return createAccordion;
  };

  const modifyFrequent = (data) => {
    navigate("/modify-frequent", { state: { id: data.FREQUENT_ID } });
  };

  const deleteFrequent = (data) => {
    Swal.fire({
      title: `자주하는 질문 게시글 삭제`,
      text: `자주하는 질문 게시글을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post("/api/delete-frequent", {
            id: data.FREQUENT_ID,
          });
          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: "삭제완료",
              text: `자주하는 질문 게시글이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            fetchingData();
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "게시글 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        } catch (error) {
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/read-frequent");
        if (!ignore) {
          setLoading(false);
          setFrequentData(result.data);
        }
      } catch (error) {
        console.log(error);
        if (!ignore) {
          setLoading(false);
        }
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    fetchingData();

    return () => {
      ignore = true;
    };
  }, []);

  return { writeFrequent, accordionMap, frequentData, isVerify, loading };
};
