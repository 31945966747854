import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/Complet.css";

function CompletPage(props) {
  const [copyInfo, setCopyInfo] = useState(false);
  const navigate = useNavigate();

  const handleMoveInquiry = () => {
    navigate(props.moveUrl, { replace: true });
  };

  const handleMoveHome = () => {
    navigate("/", { replace: true });
  };

  const handleCopyClick = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopyInfo(true);
        setTimeout(() => {
          setCopyInfo(false);
        }, [2000]);
      })
      .catch((error) => {});
  };

  return (
    <div>
      <div>
        {copyInfo ? (
          <div className="alert alert-primary" role="alert" id="copy-info">
            <span>계좌번호가 복사되었습니다.</span>
          </div>
        ) : (
          <></>
        )}

        <div className="container text-center mt-5">
          <div className="row">
            <div className="col individual-com">
              <p>{props.msg}</p>
              <p>완료</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <p>참가비 입금계좌</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <p style={{ padding: 0, margin: 0 }}>MG새마을금고</p>
              <span>9002-2006-8638-3</span>
              &nbsp; &nbsp;
              <i
                className="bi bi-clipboard"
                id="copy-icon"
                onClick={() => {
                  handleCopyClick("9002200686383");
                }}
              ></i>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col">예금주 : 여수시자전거연맹&#40;시장배&#41;</div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-6">
              <button
                className="btn-size btn-inquiry"
                onClick={handleMoveInquiry}
              >
                {props.msg} 조회 페이지 이동
              </button>
            </div>
          </div>
          <div className="row justify-content-center mt-1">
            <div className="col-lg-6">
              <button className="btn-size btn-home" onClick={handleMoveHome}>
                홈 이동
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletPage;
