import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReadyPage from "components/ReadyPage";
import "assets/css/Picture.css";

function Picture() {
  return (
    <div>
      <Helmet>
        <title>ycf - 대회사진</title>
      </Helmet>
      <Header />
      <Bread first="대회정보" second="대회사진" />
      <ExtraBold name="대회사진" />
      <div className="container">
        <div className="row picture-ready-conter-margin">
          <div className="col text-center">
            <i className="bi bi-chat-left-dots picture-ready-icon-size"></i>
            <p className="picture-ready-font-size ">준비중입니다.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

  // return (
  //   <div>
  //     <Helmet>
  //       <title>ycf - 대회사진</title>
  //     </Helmet>
  //     <Header />
  //     <Bread first="대회정보" second="대회사진" />
  //     <ExtraBold name="대회사진" />
  //     <div className="container">
  //       <div className="row">
  //         <div className="col">
  //           <div className="row">
  //             <div className="col">
  //               사진은 해당 링크를 통해 보실 수 있습니다.
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="container mt-5">
  //       <div className="row">
  //         <div
  //           className="col text-center"
  //           style={{ textDecoration: "underline" }}
  //         >
  //           <Link
  //             to="https://cafe.naver.com/cyclerpicture"
  //             target="_blank"
  //             className="link-font-size"
  //           >
  //             <i className="bi bi-link-45deg"></i>팀사이클러픽쳐
  //           </Link>
  //         </div>
  //       </div>
  //       <div className="row mt-5">
  //         <div
  //           className="col text-center"
  //           style={{ textDecoration: "underline" }}
  //         >
  //           <Link
  //             to="https://void-life14.tistory.com/"
  //             target="_blank"
  //             className="link-font-size"
  //           >
  //             <i className="bi bi-link-45deg"></i>Path_Finder814의 이것저것 담기
  //           </Link>
  //         </div>
  //       </div>
  //       <div className="row mt-5">
  //         <div
  //           className="col text-center"
  //           style={{ textDecoration: "underline" }}
  //         >
  //           <Link
  //             to="https://tinaji1004.tistory.com/m/345"
  //             target="_blank"
  //             className="link-font-size"
  //           >
  //             <i className="bi bi-link-45deg"></i>소피아님
  //           </Link>
  //         </div>
  //       </div>
  //       <div className="row mt-5">
  //         <div
  //           className="col text-center"
  //           style={{ textDecoration: "underline" }}
  //         >
  //           <Link
  //             to="https://cafe.naver.com/ArticleList.nhn?search.clubid=31070548&search.menuid=50&search.boardtype=I"
  //             target="_blank"
  //             className="link-font-size"
  //           >
  //             <i className="bi bi-link-45deg"></i>지다바이크
  //           </Link>
  //         </div>
  //       </div>
  //       <div className="row picture-container-margin text-center">
  //         <div className="col" style={{ textDecoration: "underline" }}>
  //           <Link
  //             to="https://cafe.daum.net/45br79mini0A0/_rec"
  //             target="_blank"
  //             className="link-font-size"
  //           >
  //             <i className="bi bi-link-45deg"></i>
  //             <spna>서울 동부MTB</spna>
  //           </Link>
  //         </div>
  //       </div>
  //     </div>
  //     <Footer />
  //   </div>
  // );
}

export default Picture;
