import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx";
import "assets/css/InputForm.css";
import "assets/css/Global.css";
import { useAxios } from "hooks/useAxios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

function GroupInputForm2() {
  const navigate = useNavigate();
  const [warningText, setWarningText] = useState(false);
  const [warningFile, setWarningFile] = useState(false);
  const [warningExtension, setWarningExtension] = useState(false);
  const [warningUpload, setWarningUpload] = useState(false);
  const [warningNull, setWarningNull] = useState(false);
  const [uploadFile, setUploadFile] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const [isValid, setIsValid] = useState({
    agree: false,
    hasFile: false,
  });

  const [inputData, setInputData] = useState({
    agree: false,
    groupTeamName: "",
    list: [],
  });
  const agreeRef = useRef();
  const fileInputRef = useRef();
  const { accessAxios } = useAxios();

  const handleCheckBox = (e) => {
    if (e.target.checked === true) {
      setInputData({ ...inputData, agree: true });
      setIsValid({ ...isValid, agree: true });
      setWarningText(false);
    } else {
      setInputData({ ...inputData, agree: false });
      setIsValid({ ...isValid, agree: false });
    }
  };

  const handleSetFileUpload = (e) => {
    const fileAccept = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/haansoftxlsx",
      "application/haansoftcell",
    ];

    const file = e.target.files[0];

    if (file) {
      if (fileAccept.includes(file.type)) {
        setWarningExtension(false);
        setUploadFile(e.target.files[0]);
        setWarningFile(false);
        setWarningUpload(false);
        setWarningNull(false);

        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          const worksheet = workbook.Sheets[workbook.SheetNames[0]];

          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const filteredArr = jsonData.filter((el, index) => {
            //el.length는 row 값이 0보다 큰거, index는 0부터 시작임

            return el.length > 0 && index > 11;
          });
          console.log(filteredArr);
          for (const arr of filteredArr) {
            if (arr.length < 8) {
              setWarningNull(true);
              fileInputRef.current.value = "";
              return;
            }
          }

          // if (filteredArr.length === 0) {
          //   setWarningNull(true);
          //   fileInputRef.current.value = "";
          //   return;
          // }

          const groupData = filteredArr.map((data, index) => {
            if (index === 0) {
              return {
                name: data[0],
                phoneNumber: data[1],
                birthDate: data[2],
                gender: data[3],
                address: data[4],
                detailAddress: data[5],
                course: data[6],
                cycle: data[7],
                isLeader: 1,
              };
            } else {
              return {
                name: data[0],
                phoneNumber: data[1],
                birthDate: data[2],
                gender: data[3],
                address: data[4],
                detailAddress: data[5],
                course: data[6],
                cycle: data[7],
                isLeader: 0,
              };
            }
          });

          setInputData({ ...inputData, list: groupData });
        };

        if (file) {
          reader.readAsArrayBuffer(file);
        }
      } else if (!fileAccept.includes(file.type)) {
        fileInputRef.current.value = "";
        setInputData({ ...inputData, list: [] });
        setWarningExtension(true);
        setWarningUpload(false);
      }
    } else {
      setInputData({ ...inputData, list: [] });
      setWarningUpload(true);
      setWarningExtension(false);
    }
  };

  const handleFileUpload = async () => {
    const formData = new FormData();

    formData.append("file", fileInputRef.current.files[0]);
    formData.append("groupList", JSON.stringify(inputData));
    formData.append(
      "leader",
      JSON.stringify({
        leaderName: inputData.list[0].name,
        leaderPhoneNumber: inputData.list[0].phoneNumber,
      })
    );

    try {
      const result = await accessAxios.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (result.data.resultCode === "00") {
        navigate("/group-complet", { replace: true });
      } else {
        Swal.fire({
          title: "단체참가신청을 실패했습니다.",
          text: "관리자에게 문의해주세요.",
          icon: "error",
          showConfirmButton: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetData = (e) => {
    if (e.target.id === "agree") {
      setInputData({ ...inputData, agree: e.target.checked });

      if (isValid.agree === true) {
        setIsValid({ ...isValid, agree: false });
      }
    } else if (e.target.id === "groupTeamName") {
      setInputData({ ...inputData, groupTeamName: e.target.value });
    }
  };

  const onClickSubmit = async () => {
    if (inputData.list.length == 0) {
      setWarningExtension(false);
      setIsValid({ ...isValid, hasFile: true });
      setWarningFile(true);
    } else if (inputData.agree === false) {
      setIsValid({ ...isValid, agree: true });
      setWarningText(true);
    } else {
      handleFileUpload();
    }
  };

  useEffect(() => {}, [inputData, isValid]);

  return (
    <div>
      <div className="container container-margin-left">
        <form>
          <div className="row mb-3">
            <label
              htmlFor="groupTeamName"
              className="col-sm-3 col-form-label bold-font"
            >
              단체 이름
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control input-max-width"
                id="groupTeamName"
                onChange={handleGetData}
              />
            </div>
          </div>
          <div className="row mt-3">
            <label
              htmlFor="forFile"
              className="col-sm-3 col-form-label bold-font form-label"
            >
              파일업로드<span className="essential">*</span>
            </label>
            <div className="col-sm-9">
              <input
                className="form-control"
                type="file"
                id="formFile"
                onChange={(e) => {
                  handleSetFileUpload(e);
                }}
                accept=".xlsx, .xls, .cell"
                ref={fileInputRef}
              />
              <p
                className={classnames({
                  "warning-text": warningExtension,
                  "none-display": !warningExtension,
                })}
              >
                파일의 .확장자를 확인해주세요. (.xls, .xlsx)
              </p>
              <p
                className={classnames({
                  "group-count": true,
                  "none-display": inputData.list.length == 0 || warningNull,
                })}
              >
                {`단체참가자 수 : ${inputData.list.length} 명`}
              </p>
              <p
                className={classnames({
                  "warning-text": warningFile,
                  "none-display": !warningFile,
                })}
              >
                파일을 첨부하여주세요.
              </p>
              <p
                className={classnames({
                  "warning-text": warningNull,
                  "none-display": !warningNull,
                })}
              >
                참가자 정보 입력이 누락되었습니다. 빠짐없이 입력하여 다시
                업로드해주세요.
              </p>
            </div>
          </div>
          <div className="mt-5"></div>

          <div className="row mb-3 ps-2">
            <div className="col-sm-3"></div>
            <div className="col-sm-9">
              <p style={{ padding: 0, margin: 0 }}>
                예금주 : 여수시자전거연맹&#40;시장배&#41;
              </p>
              <p style={{ padding: 0, margin: 0 }}>
                참가비 입금계좌번호 : 9002-2006-8638-3
              </p>
            </div>
          </div>

          <div className="row mt-3">
            <label
              htmlFor="argee"
              className="col-sm-3 col-form-label bold-font"
            ></label>

            <div className="col-sm-9">
              <input
                type="checkbox"
                className="btn-check"
                id="agree"
                autoComplete="off"
                onChange={handleCheckBox}
                ref={agreeRef}
              />
              <label
                className={classnames(
                  "btn",
                  "bold-font",
                  "btn-outline-primary"
                )}
                htmlFor="agree"
              >
                약관동의
              </label>
              <p
                className={classnames({
                  "warning-text": warningText,
                  "none-display": !warningText,
                })}
              >
                약관을 동의해주세요.
              </p>
            </div>
          </div>
          <div className="row mt-1">
            <label
              htmlFor="agree-text"
              className="col-sm-3 col-form-label bold-font"
            ></label>
            <div className="col-sm-9">
              <div>
                <textarea
                  className="form-control input-max-width footer-font"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  readOnly
                  value={`개인정보 활용 동의
1. 개인정보의 수집·이용 목적
 - 귀하의 개인정보는 자전거 대회 접수와 관련하여 개인의 참가정보를 조회하기 위한 목적으로 수집·이용됩니다.
2. 수집·이용할 개인정보의 항목
 - 개인 또는 법인식별 정보(성명, 성별, 생년월일, 주소, 연락처(휴대전화번호), e-mail주소)
3. 개인정보의 보유·이용 기간
 - 귀하의 개인 또는 법인정보는 수집·이용에 관한 동의일로부터 본인에 대한 신용정보 제공·조회 동의의 효력기간까지 보유·이용됩니다.
 - 단, 신용정보 제공·조회 동의의 효력 기간 종료 후에는 금융사고 조사, 분쟁 해결, 민원처리 및 법령상 의무이행만을 위하여 보유·이용됩니다.
4. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
 - 위 개인 또는 법인정보의 수집·이용에 동의는 본대회의 수행을 위해 필수적이므로 이에 동의하셔야 이후 절차를 진행할 수 있습니다. 선택항목의 수집·이용에 거부할 수 있으며, 다만 동의 하지 않으시는 경우 본 대회의 참여가 불가합니다.`}
                  style={{ fontSize: "10px" }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="name"
              className="col-sm-3 col-form-label bold-font"
            ></label>
            <div className="col mt-2 d-flex align-items-center">
              <button
                type="button"
                className={classnames(
                  "btn",
                  "btn-outline-primary",
                  "input-btn-width",
                  "bold-font"
                )}
                disabled={!isDisable}
                onClick={onClickSubmit}
              >
                단체참가신청
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default GroupInputForm2;
