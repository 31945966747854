import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import Bold from "components/Bold";
import Download from "components/Download";
import { Helmet } from "react-helmet";
import "assets/css/Course.css";

function Course() {
  return (
    <div>
      <Helmet>
        <title>ycf - 대회코스</title>
      </Helmet>
      <Header />
      <Bread first="대회정보" second="대회코스" />
      <ExtraBold name="대회코스" />
      <Bold name="힐클라임" />
      <div className="container course-margin">
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <img
              src={require("assets/img/hill_climb_course/hill_climb_course.png")}
              alt="infor_image"
              className="info-image-size"
            />
          </div>
        </div>
      </div>

      <Bold name="코스파일" />
      <div className="container course-margin">
        <div className="row">
          <div className="col-md-3">
            <Download
              url="/api/download-gpx"
              downloadFileName="2025_yeosu_hill_climb.gpx"
              buttonName="힐클라임 .GPX"
            />
          </div>
          <div className="col-md-3">
            <Download
              url="/api/download-tcx"
              downloadFileName="2025_yeosu_hill_climb.tcx"
              buttonName="힐클라임 .TCX"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Course;
