import React from "react";
import NaverLogin from "./NaverLogin";
import { Link } from "react-router-dom";

import "assets/css/Login.css";
import "assets/css/Global.css";

function LoginPage(props) {
  return (
    <>
      <div className="login-container ">
        <img
          className="login-container__img"
          src={require("assets/img/ycf_sub_logo.png")}
          alt="메인사진"
        />
        <p className="login-container__p extar-bold-font">LOGIN</p>
        <div className="login-container__items bold-font">
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              onChange={props.fn.onChangeId}
            />
            <label htmlFor="floatingInput">ID</label>
          </div>
        </div>
        <div className="login-container__items bold-font">
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              onChange={props.fn.onChangePw}
              onKeyDown={props.fn.handleOnKeyPress}
            />
            <label htmlFor="floatingPassword">PASSWORD</label>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-outline-info login-container__btn bold-font"
          onClick={props.fn.handleLogin}
        >
          Login
        </button>
        <Link className="mt-3" to="/">
          메인페이지로 돌아가기
        </Link>
        <div className="border-top mx-auto mt-5 mb-2 login-container__bottom-border"></div>
        <NaverLogin />
      </div>
    </>
  );
}

export default LoginPage;
