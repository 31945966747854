import React, { useState, useEffect, useRef } from "react";
import DaumPost3 from "components/DaumPost3";
import { useAxios } from "hooks/useAxios";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "assets/css/InputForm.css";
import "assets/css/Global.css";
import Spinner from "components/Spinner";

function IndividualInputForm() {
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState({
    name: false,
    phoneNumber: false,
    birthDate: false,
    gender: false,
    zipCode: false,
    address: false,
    zipAndAddress: false,
    detailAddress: false,
    course: false,
    cycle: false,
  });

  const [isDisable, setIsDisable] = useState(true);
  const [inputData, setInputData] = useState({
    name: "",
    phoneNumber: "",
    birthDate: "",
    gender: "",
    zipCode: "",
    address: "",
    detailAddress: "",
    course: "hill",
    cycle: "",
  });

  const navigate = useNavigate();

  const nameRef = useRef();
  const phoneNumberRef = useRef();
  const birthDateRef = useRef();
  const genderRef = useRef();
  const detailAddressRef = useRef();

  const courseRef = useRef();
  const cycleRef = useRef();
  const { accessAxios } = useAxios();

  const allInputIsValue = () => {
    if (
      inputData.name !== "" &&
      inputData.phoneNumber !== "" &&
      inputData.birthDate !== "" &&
      inputData.gender !== "" &&
      inputData.zipCode !== "" &&
      inputData.address !== "" &&
      inputData.detailAddress !== "" &&
      inputData.course !== "" &&
      inputData.cycle !== ""
    ) {
      setIsDisable(false);
    }
  };

  const getAddress = (data) => {
    setInputData({
      ...inputData,
      zipCode: data.zipCode,
      address: data.address,
      detailAddress: data.detailAddress,
    });

    if (isValid.zipAndAddress === true) {
      setIsValid({ ...isValid, zipAndAddress: false });
    } else if (isValid.detailAddress === true) {
      setIsValid({ ...isValid, detailAddress: false });
    }
  };

  const handleGetData = (e) => {
    if (e.target.id === "name") {
      setInputData({ ...inputData, name: e.target.value });

      if (isValid.name === true) {
        setIsValid({ ...isValid, name: false });
      }
    } else if (e.target.id === "phone") {
      setInputData({ ...inputData, phoneNumber: e.target.value });

      if (isValid.phoneNumber === true) {
        setIsValid({ ...isValid, phoneNumber: false });
      }
    } else if (e.target.id === "yymmdd") {
      setInputData({ ...inputData, birthDate: e.target.value });

      if (isValid.birthDate === true) {
        setIsValid({ ...isValid, birthDate: false });
      }
    } else if (e.target.id === "male") {
      setInputData({ ...inputData, gender: e.target.value });

      if (isValid.gender === true) {
        setIsValid({ ...isValid, gender: false });
      }
    } else if (e.target.id === "female") {
      setInputData({ ...inputData, gender: e.target.value });

      if (isValid.gender === true) {
        setIsValid({ ...isValid, gender: false });
      }
    }
    // else if (e.target.id === "granfondo") {
    //   setInputData({ ...inputData, course: e.target.value });

    //   if (isValid.course === true) {
    //     setIsValid({ ...isValid, course: false });
    //   }
    // } else if (e.target.id === "mediofondo") {
    //   setInputData({ ...inputData, course: e.target.value });

    //   if (isValid.course === true) {
    //     setIsValid({ ...isValid, course: false });
    //   }
    // }
    else if (e.target.id === "road") {
      setInputData({ ...inputData, cycle: e.target.value });

      if (isValid.cycle === true) {
        setIsValid({ ...isValid, cycle: false });
      }
    } else if (e.target.id === "mtb") {
      setInputData({ ...inputData, cycle: e.target.value });

      if (isValid.cycle === true) {
        setIsValid({ ...isValid, cycle: false });
      }
    } else if (e.target.id === "etc") {
      setInputData({ ...inputData, cycle: e.target.value });

      if (isValid.cycle === true) {
        setIsValid({ ...isValid, cycle: false });
      }
    }

    allInputIsValue();
  };

  const onClickSubmit = async () => {
    const phoneRegex =
      /^(01[0-9]{8}|010[0-9]{8}|011[0-9]{7}|016[0-9]{7}|017[0-9]{7}|018[0-9]{7}|019[0-9]{7})$/;
    const birthDateBrRegex =
      /\d{2}([0][13578]|[1][012])([0][1-9]|[12][0-9]|[3][01])$/;

    if (inputData.name === "") {
      nameRef.current.focus();
      setIsValid({ ...isValid, name: true });
    } else if (
      inputData.phoneNumber === "" ||
      !phoneRegex.test(inputData.phoneNumber)
    ) {
      phoneNumberRef.current.focus();
      setIsValid({ ...isValid, phoneNumber: true });
    } else if (inputData.birthDate === "") {
      birthDateRef.current.focus();
      setIsValid({ ...isValid, birthDate: true });
    } else if (inputData.gender === "") {
      genderRef.current.focus();
      setIsValid({ ...isValid, gender: true });
    } else if (inputData.zipCode === "") {
      genderRef.current.focus();
      setIsValid({ ...isValid, zipAndAddress: true });
    } else if (inputData.detailAddress === "") {
      genderRef.current.focus();
      setIsValid({ ...isValid, detailAddress: true });
    }
    //  else if (inputData.course === "") {
    //   courseRef.current.focus();
    //   setIsValid({ ...isValid, course: true });
    // }
    else if (inputData.cycle === "") {
      cycleRef.current.focus();
      setIsValid({ ...isValid, cycle: true });
    } else {
      try {
        setLoading(true);
        const result = await accessAxios.post("/api/add-individual", {
          ...inputData,
        });

        if (result.data.resultCode === "00") {
          navigate("/admin-add-individual-complete", { replace: true });
        } else {
          setLoading(false);
          Swal.fire({
            title: "개인참가신청을 실패했습니다.",
            text: "관리자에게 문의해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {}, [inputData, isValid, isDisable]);

  return (
    <div className="container container-margin-left">
      {loading ? <Spinner /> : <></>}
      <form>
        <div className="row mb-3">
          <label htmlFor="name" className="col-sm-3 col-form-label bold-font">
            이름<span className="essential">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className={classnames("form-control", "input-max-width", {
                "is-invalid": isValid["name"],
              })}
              id="name"
              onChange={handleGetData}
              ref={nameRef}
              maxLength={20}
            />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="phone" className="col-sm-3 col-form-label bold-font">
            전화번호<span className="essential">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="tel"
              placeholder="(-)제외하고 입력 예시) 01012341234"
              className={classnames("form-control input-max-width", {
                "is-invalid": isValid.phoneNumber,
              })}
              id="phone"
              onChange={handleGetData}
              ref={phoneNumberRef}
              maxLength={11}
            />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="yymmdd" className="col-sm-3 col-form-label bold-font">
            생년월일<span className="essential">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="tel"
              placeholder="예)990101"
              className={classnames("form-control input-max-width", {
                "is-invalid": isValid.birthDate,
              })}
              id="yymmdd"
              onChange={handleGetData}
              ref={birthDateRef}
              maxLength={6}
            />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="gender" className="col-sm-3 col-form-label bold-font">
            성별<span className="essential">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="male"
              autoComplete="off"
              value="male"
              onChange={handleGetData}
              ref={genderRef}
              checked={inputData.gender === "male" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.gender,
                "btn-outline-danger": isValid.gender,
              })}
              htmlFor="male"
            >
              남자
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="female"
              autoComplete="off"
              value="female"
              onChange={handleGetData}
              checked={inputData.gender === "female" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.gender,
                "btn-outline-danger": isValid.gender,
              })}
              htmlFor="female"
            >
              여자
            </label>
          </div>
        </div>
        <DaumPost3
          getAddress={getAddress}
          ref={detailAddressRef}
          isValid={isValid}
        />
        <div className="mt-5"></div>
        <div className="row mb-3">
          <label htmlFor="gender" className="col-sm-3 col-form-label bold-font">
            대회코스
            {/* <span className="essential">*</span> */}
          </label>
          <div className="col-sm-9">
            {/* <input
              type="radio"
              className="btn-check"
              name="course"
              id="granfondo"
              autoComplete="off"
              value="granfondo"
              onChange={handleGetData}
              ref={courseRef}
              checked={inputData.course === "granfondo" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.course,
                "btn-outline-danger": isValid.course,
              })}
              htmlFor="granfondo"
            >
              그란폰도
            </label>
            <input
              type="radio"
              className="btn-check"
              name="course"
              id="mediofondo"
              autoComplete="off"
              value="mediofondo"
              onChange={handleGetData}
              checked={inputData.course === "mediofondo" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.course,
                "btn-outline-danger": isValid.course,
              })}
              htmlFor="mediofondo"
            >
              메디오폰도
            </label> */}
            <input
              type="radio"
              className="btn-check"
              name="course"
              id="hill"
              autoComplete="off"
              ref={courseRef}
              value="hill"
              onChange={handleGetData}
              checked={true}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.course,
                "btn-outline-danger": isValid.course,
              })}
              htmlFor="hill"
            >
              힐클라임
            </label>
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="gender" className="col-sm-3 col-form-label bold-font">
            구분<span className="essential">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="radio"
              className="btn-check"
              name="cycle"
              id="road"
              autoComplete="off"
              value="ROAD"
              onChange={handleGetData}
              ref={cycleRef}
              checked={inputData.cycle === "ROAD" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.cycle,
                "btn-outline-danger": isValid.cycle,
              })}
              htmlFor="road"
            >
              ROAD
            </label>
            <input
              type="radio"
              className="btn-check"
              name="cycle"
              id="mtb"
              autoComplete="off"
              value="MTB"
              onChange={handleGetData}
              checked={inputData.cycle === "MTB" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.cycle,
                "btn-outline-danger": isValid.cycle,
              })}
              htmlFor="mtb"
            >
              MTB
            </label>
            <input
              type="radio"
              className="btn-check"
              name="cycle"
              id="etc"
              autoComplete="off"
              value="기타"
              onChange={handleGetData}
              checked={inputData.cycle === "기타" ? true : false}
            />
            <label
              className={classnames("btn", "bold-font", {
                "btn-outline-primary": !isValid.cycle,
                "btn-outline-danger": isValid.cycle,
              })}
              htmlFor="etc"
            >
              기타
            </label>
          </div>
        </div>
        <div className="row mb-3">
          <label
            htmlFor="name"
            className="col-sm-3 col-form-label bold-font"
          ></label>
          <div className="col mt-2 d-flex align-items-center">
            <button
              type="button"
              className={classnames(
                "btn",
                "btn-outline-primary",
                "input-btn-width",
                "bold-font"
              )}
              onClick={onClickSubmit}
            >
              개인참가신청
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default IndividualInputForm;
